import useGlobalIsError from './utils/useGlobalIsError';
import useGlobalIsLoading from './utils/useGlobalIsLoading';
import useLoadingText from './utils/useLoadingText';
import { Loading } from '@optimization/ssi-common';

const GlobalLoading = () => {
  const isLoading = useGlobalIsLoading();
  const isError = useGlobalIsError();
  const loadingText = useLoadingText({ isLoading });

  return <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />;
};

export default GlobalLoading;
