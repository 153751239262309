import { globalLoadingEnabledSelector } from '../globalLoadingSlice';
import { hereMapsApi } from 'app/services/hereMaps';
import { microsoftApi } from 'app/services/microsoft';
import { solutionApi } from 'app/services/solution';
import { useAppSelector } from 'app/hooks';

const endpointsToExclude = ['getAddresses'];

const useGlobalIsLoading = () => {
  const globalLoadingEnabled = useAppSelector(globalLoadingEnabledSelector);

  const isPending = useAppSelector((state) => {
    const checkPending = (api: typeof solutionApi | typeof microsoftApi | typeof hereMapsApi) =>
      Object.values(state[api.reducerPath]?.queries || {})
        .filter((query) => !endpointsToExclude.includes(query?.endpointName || ''))
        .some((query) => {
          return query?.status === 'pending';
        }) ||
      Object.values(state[api.reducerPath]?.mutations || {})
        .filter((mutation) => !endpointsToExclude.includes(mutation?.endpointName || ''))
        .some((mutation) => {
          return mutation?.status === 'pending';
        });

    return checkPending(solutionApi) || checkPending(microsoftApi) || checkPending(hereMapsApi);
  });

  return globalLoadingEnabled && isPending;
};

export default useGlobalIsLoading;
