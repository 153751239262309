import { setGlobalLoadingEnabled } from './globalLoadingSlice';
import { useAppDispatch } from 'app/hooks';
import { useEffect } from 'react';

const DisableGlobalLoading = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setGlobalLoadingEnabled({ enabled: false }));

    return () => {
      dispatch(setGlobalLoadingEnabled({ enabled: true }));
    };
  }, [dispatch]);

  return null;
};

export default DisableGlobalLoading;
