import { Rgb } from '@optimization/ssi-common';

export const APP_TITLE = import.meta.env.VITE_APP_TITLE;
export const APP_USP = import.meta.env.VITE_APP_USP;

export const MULTIPLE_VALUES_SEPARATOR = ',';

export const MAX_CABLE_POWER_KW = 325;

export const TYPE_DESIGNATION_MODULAR = '';

export const SUPPORT_NAME = 'E-Mobility sales tools';
export const SUPPORT_EMAIL = 'emobilitysalestools@scania.com';

export const MODE = import.meta.env.MODE;

export const MOCKING_ENABLED = import.meta.env.VITE_MOCKING_ENABLED === 'true';

export const CHARGING_POINTS_ENABLED = import.meta.env.VITE_CHARGING_POINTS_ENABLED === 'true';

export const THEME_COLOR = import.meta.env.VITE_THEME_COLOR;

export const SOLUTION_ENDPOINT = import.meta.env.VITE_SOLUTION_ENDPOINT;

export const REVERSE_GEOCODE_ENDPOINT = 'https://revgeocode.search.hereapi.com';

export const MULTIPLE_REVERSE_GEOCODE_ENDPOINT = 'https://multi-revgeocode.search.hereapi.com';

export const AUTOCOMPLETE_ENDPOINT = 'https://autocomplete.search.hereapi.com';

export const LOOKUP_ENDPOINT = 'https://lookup.search.hereapi.com';

export const HERE_MAPS_API_KEY = 'OcOv22pL0eb89DYyu3mAEhRtmzq2RMqAmOLnTWPsEUw';

export const PRESENTATION_APP_URL = import.meta.env.VITE_PRESENTATION_APP_URL;

export const APP_IS_UPDATED_STORAGE_KEY = 'appIsUpdated';

export const PERFORMANCE_STEP_TOOLTIP =
  "The vehicle candidate's year corresponds to the performance step in the customer electrification plan";

export const WINTER_MIN_TEMP_DEFAULT = -5;
export const MEDIAN_TEMP_DEFAULT = 12;
export const SUMMER_MAX_TEMP_DEFEAULT = 20;

export const GTW_MIN_DEFAULT = 10000;
export const GTW_MAX_DEFAULT = 50000;

export const MAP_ANIMATION_SPEED = 10;

export const MAP_ORIGIN_LATITUDE = 17.570692;
export const MAP_ORIGIN_LONGITUDE = -3.996166;

export const MIN_STARTING_GTW = 8000;
export const MAX_STARTING_GTW = 200000;

// @ts-ignore
export const USER_LANGUAGE = navigator.language || navigator.userLanguage;
export const baseRangeColor: Rgb = { r: 61, g: 124, b: 214 };
export const extraRangeColor: Rgb = { r: 224, g: 207, b: 173 };
export const remainingDistanceColor: Rgb = { r: 243, g: 83, b: 89 };
export const dailyMaxRangeColor: Rgb = { r: 255, g: 255, b: 255 };

export const NO_DEPOT_NAME = 'No depot';
