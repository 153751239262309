import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { transformGetAddressesItem } from '@optimization/sa-common';
import type {
  Position,
  ReverseGeocodingLocationItem,
  ReverseGeocodingResponse,
  GetAddressesItemTransformed,
  GetAddressesResponse,
  LookupAddressResponse,
  MultipleReverseGeocodingResult,
  MultipleReverseGeocodingResponse,
} from '@optimization/sa-common';
import {
  AUTOCOMPLETE_ENDPOINT,
  HERE_MAPS_API_KEY,
  LOOKUP_ENDPOINT,
  REVERSE_GEOCODE_ENDPOINT,
  MULTIPLE_REVERSE_GEOCODE_ENDPOINT,
  USER_LANGUAGE,
} from 'app/config';

interface GetAddressesArgs {
  searchTerm: string;
}

interface LookupAddressArgs {
  id: string;
}

type MultipleReverseGeocodingArgs = { id: string | number; longitude: number; latitude: number }[];

const baseQuery = fetchBaseQuery({
  baseUrl: '',
});

const baseQueryRetry = retry(baseQuery, {
  maxRetries: 0,
});

export const hereMapsApi = createApi({
  reducerPath: 'hereMapsApi',
  keepUnusedDataFor: 60 * 60 * 24 * 7,
  baseQuery: baseQueryRetry,
  endpoints: (build) => ({
    reverseGeocoding: build.query<ReverseGeocodingLocationItem[], Position>({
      query: ({ latitude, longitude }) => ({
        url: `${REVERSE_GEOCODE_ENDPOINT}/v1/revgeocode?lang=${USER_LANGUAGE}&at=${latitude},${longitude},250&limit=1&apiKey=${HERE_MAPS_API_KEY}`,
      }),
      transformResponse: (response: ReverseGeocodingResponse) => response.items,
    }),
    multipleReverseGeocoding: build.mutation<MultipleReverseGeocodingResult[], MultipleReverseGeocodingArgs>({
      query: (items) => ({
        url: `${MULTIPLE_REVERSE_GEOCODE_ENDPOINT}/v1/multi-revgeocode?lang=${USER_LANGUAGE}&apiKey=${HERE_MAPS_API_KEY}`,
        method: 'POST',
        body: items.map(({ id, latitude, longitude }) => `id=${id}&at=${latitude},${longitude}`).join('\n'),
      }),
      transformResponse: (response: MultipleReverseGeocodingResponse) => response.results,
    }),
    getAddresses: build.query<GetAddressesItemTransformed[], GetAddressesArgs>({
      query: ({ searchTerm }) => ({
        url: `${AUTOCOMPLETE_ENDPOINT}/v1/autocomplete?lang=${USER_LANGUAGE}&q=${encodeURIComponent(searchTerm)}&limit=4&apiKey=${HERE_MAPS_API_KEY}`,
      }),
      transformResponse: (response: GetAddressesResponse) =>
        response.items.map((item) => transformGetAddressesItem(item)),
    }),
    lookupAddress: build.query<LookupAddressResponse, LookupAddressArgs>({
      query: ({ id }) => ({
        url: `${LOOKUP_ENDPOINT}/v1/lookup?lang=${USER_LANGUAGE}&id=${id}&apiKey=${HERE_MAPS_API_KEY}`,
      }),
    }),
  }),
});

export const {
  useReverseGeocodingQuery,
  useMultipleReverseGeocodingMutation,
  useLazyReverseGeocodingQuery,
  useLazyGetAddressesQuery,
  useLookupAddressQuery,
} = hereMapsApi;
