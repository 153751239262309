import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface GlobalLoadingState {
  enabled: boolean;
}

const initialState: GlobalLoadingState = {
  enabled: true,
};

export const globalLoadingSlice = createSlice({
  name: 'globalLoading',
  initialState,
  reducers: {
    setGlobalLoadingEnabled: (state, action: PayloadAction<{ enabled: boolean }>) => {
      state.enabled = action.payload.enabled;
    },
  },
});

export const { setGlobalLoadingEnabled } = globalLoadingSlice.actions;

export const globalLoadingEnabledSelector = (state: RootState) => state.globalLoading.enabled;

export default globalLoadingSlice.reducer;
