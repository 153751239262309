import { ApiEnvelope, ApiEnvelopeNoContent, EmptyObject, RecursivePartial } from '@optimization/ssi-common';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { RouteAndCalculateArgs } from 'features/candidate/Candidates/Table/Detail/Operation/utils/routingTypes';
import { SOLUTION_ENDPOINT } from 'app/config';
import {
  RoutePointsResponse,
  RoutePointsEnvelope,
  GetChassisRouteArgs,
} from 'features/candidate/Candidates/Table/Detail/Operation/utils/routingTypes';
import {
  CalculateTcoEnvelope,
  ChargingPointResponse,
  CreateChassisArgs,
  CreateDepotArgs,
  CreateDepotEnvelope,
  CreateDepotResponseV2VM,
  CreateManualVehicleArgs,
  DeleteDepotsArgs,
  DeleteVehicleChargingEventsArgs,
  DeleteVehiclesArgs,
  DepotTransformed,
  EnvironmentalImpactVM,
  FactBasicDataResponse,
  GeospatialChargingPoint,
  GetSolutionPresentationEnvelope,
  GetChassisByCustomerNameArgs,
  GetCustomersArgs,
  GetCustomersChassisEnvelope,
  GetCustomersEnvelope,
  GetPresentationExportArgs,
  GetDepotEnvelope,
  GetFactBasicDataEnvelope,
  GetHomeBasesEnvelope,
  GetPtoEnvelope,
  GetStopsArgs,
  GetStopsEnvelope,
  GetStopsMetadataArgs,
  GetStopsMetadataEnvelope,
  GetStopsStatusArgs,
  GetStopsStatusEnvelope,
  GetTcoVehicleArgs,
  GetTripsArgs,
  GetTripsEnvelope,
  GetTripsStatusArgs,
  GetTripsStatusEnvelope,
  GetUserEnvelope,
  GetVehicleArgs,
  GetVehicleEnvelope,
  HomeBase,
  InitStopsArgs,
  InitStopsEnvelope,
  InitStopsResponse,
  InitTripsArgs,
  InitTripsEnvelope,
  InitTripsResponse,
  ListDepotEnvelope,
  ListDepotTransformed,
  ListTcoVehicleEnvelope,
  ListVehicleEnvelope,
  ListVehicleTransformed,
  MapLocationArgs,
  MapLocationEnvelope,
  PtoDevices,
  SolutionEnvelope,
  SolutionMetadataEnvelope,
  SolutionMetadataTransformed,
  SolutionTransformed,
  Stops,
  StopsMetadataWithTimeWindowResponseVM,
  StopsStatus,
  TcoVehicleVM,
  transformDepot,
  transformListDepot,
  transformListVehicle,
  transformSolution,
  transformSolutionMetadata,
  transformVehicle,
  Trips,
  TripsStatus,
  UpdateDepotArgs,
  UpdateMultipleVehicleArgs,
  UpdateTcoApiEnvelope,
  UpdateVehicleArgs,
  UpdateVehicleChargingEventsArgs,
  UpdateVehicleEnvelope,
  UpdateVehicleResponseV2VM,
  UserVM,
  ValidateChargingEventEnvelope,
  ValidateChargingEventResponseVM,
  ValidateChassisNumbersV2VM,
  ValidateChassisNumbersVehicleEnvelope,
  ValidateChassisNumbersVehicleResponseVM,
  ValidateDepotSetupEnvelope,
  ValidateDepotSetupResponseV2VM,
  ValidateVehicleChargingEventsArgs,
  VehicleTcoArgs,
  VehicleTcoSettingResponseVM,
  VehicleTransformed,
} from '@optimization/sa-common';

const baseQuery = fetchBaseQuery({
  baseUrl: `${SOLUTION_ENDPOINT}/api`,
  prepareHeaders: (headers, { getState }) => {
    const idToken = (getState() as RootState).auth.idToken;
    const xSessionId = sessionStorage.getItem('x_session_id');
    if (idToken) {
      headers.set('authorization', `Bearer ${idToken}`);
    }
    if (xSessionId) {
      headers.set('x-session-id', xSessionId);
    }
    return headers;
  },
});

const baseQueryRetry = retry(baseQuery, {
  maxRetries: 0,
});

export const tagTypes = [
  'Depots',
  'Solutions',
  'User',
  'Vehicles',
  'EnvironmentalImpact',
  'HomeBases',
  'Routes',
] as const;

export const solutionApi = createApi({
  reducerPath: 'solutionApi',
  tagTypes,
  baseQuery: baseQueryRetry,
  endpoints: (build) => ({
    getSolutions: build.query<SolutionMetadataTransformed[], void>({
      query: () => ({ url: 'v1/Solutions' }),
      providesTags: (solutions) => {
        const tags: any = ['Solutions' as const];

        if (solutions) {
          for (const solution of solutions) {
            tags.push({ type: 'Solutions' as const, id: solution.Id });
          }
        }

        return tags;
      },
      transformResponse: (response: SolutionMetadataEnvelope) =>
        response.Content.Items.map((solution) => transformSolutionMetadata(solution)),
    }),
    getSolution: build.query<SolutionTransformed, number | string>({
      query: (id) => ({ url: `v1/Solutions/${id}` }),
      providesTags: (_, __, id) => [{ type: 'Solutions', id }],
      transformResponse: (response: SolutionEnvelope) => transformSolution(response.Content),
    }),
    addSolution: build.mutation<SolutionEnvelope, Partial<SolutionTransformed>>({
      query: (body) => ({ url: 'v1/Solutions', method: 'POST', body }),
      invalidatesTags: ['Solutions'],
    }),
    deleteSolution: build.mutation<any, { solutionId: number | string }>({
      query: ({ solutionId }) => ({
        url: `v1/Solutions/${solutionId}`,
        method: 'DELETE',
      }),
    }),
    updateSolution: build.mutation<null, Partial<SolutionTransformed>>({
      query: ({ Id, ...body }) => ({
        url: `v1/Solutions/${Id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { Id }) => [{ type: 'Solutions', id: Id }],
    }),
    getVehicles: build.query<ListVehicleTransformed[], string>({
      query: (solutionId) => ({ url: `v2/Solutions/${solutionId}/Vehicles` }),
      providesTags: (vehicles) => {
        const tags: any = ['Vehicles' as const];

        if (vehicles) {
          for (const vehicle of vehicles) {
            tags.push({ type: 'Vehicles' as const, id: vehicle.Id });
          }
        }

        return tags;
      },
      transformResponse: (response: ListVehicleEnvelope) =>
        response.Content.Items.map((item) => transformListVehicle(item)),
    }),
    getVehicle: build.query<VehicleTransformed, GetVehicleArgs>({
      query: ({ solutionId, vehicleId }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/${vehicleId}`,
      }),
      providesTags: (_, __, { vehicleId }) => [{ type: 'Vehicles', id: vehicleId }],
      transformResponse: (response: GetVehicleEnvelope) => transformVehicle(response.Content),
    }),
    validateChassis: build.mutation<ValidateChassisNumbersVehicleResponseVM, ValidateChassisNumbersV2VM>({
      query: (body) => ({
        url: `v1/ChassisNumbers/Validate`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ValidateChassisNumbersVehicleEnvelope) => response.Content,
    }),
    createManualVehicle: build.mutation<ApiEnvelopeNoContent, CreateManualVehicleArgs>({
      query: ({ solutionId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/Manual`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { solutionId, noInvalidates }) =>
        noInvalidates ? [] : [{ type: 'Solutions', id: solutionId }, 'Vehicles'],
    }),
    addChassisToSolution: build.mutation<ApiEnvelopeNoContent, CreateChassisArgs>({
      query: ({ solutionId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/Chassis`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { solutionId }) => [{ type: 'Solutions', id: solutionId }, 'Vehicles', 'HomeBases'],
    }),
    updateVehicle: build.mutation<UpdateVehicleResponseV2VM, UpdateVehicleArgs>({
      query: ({ solutionId, vehicle }) => {
        const { ...body } = vehicle;
        return {
          url: `v2/Solutions/${solutionId}/Vehicles/${vehicle.Id}`,
          method: 'PUT',
          body,
        };
      },
      transformResponse: (response: UpdateVehicleEnvelope) => response.Content,
      invalidatesTags: (updateVehicleResponseV2VM, __, { vehicle, noInvalidates }) => {
        const tags: any[] = [{ type: 'EnvironmentalImpact' as const, id: vehicle.Id }];

        if (!noInvalidates) {
          if (updateVehicleResponseV2VM?.UpdateAll) {
            tags.push('Vehicles' as const);
            tags.push('Depots' as const);
          } else {
            tags.push({ type: 'Vehicles' as const, id: vehicle.Id });

            if (vehicle.HomeDepotId) {
              tags.push({
                type: 'Depots' as const,
                id: vehicle.HomeDepotId,
              });
            }
          }
        }

        return tags;
      },
    }),
    updateMultipleVehicles: build.mutation<ApiEnvelopeNoContent, UpdateMultipleVehicleArgs>({
      query: ({ solutionId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/Multiple`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { Ids }) => Ids.map((id) => ({ type: 'Vehicles', id })),
    }),
    validateVehicleChargingEvents: build.mutation<ValidateChargingEventResponseVM, ValidateVehicleChargingEventsArgs>({
      query: ({ solutionId, vehicleId, PerformanceStep, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/${vehicleId}/ChargingEvents/${PerformanceStep}/Validate`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ValidateChargingEventEnvelope) => response.Content,
    }),
    updateVehicleChargingEvents: build.mutation<ApiEnvelopeNoContent, UpdateVehicleChargingEventsArgs>({
      query: ({ solutionId, vehicleId, PerformanceStep, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/${vehicleId}/ChargingEvents/${PerformanceStep}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Vehicles', 'Depots'],
    }),
    deleteChargingEvents: build.mutation<ApiEnvelopeNoContent, DeleteVehicleChargingEventsArgs>({
      query: ({ solutionId, vehicleId }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/${vehicleId}/ChargingEvents`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { vehicleId, noInvalidates }) =>
        noInvalidates ? [] : [{ type: 'Vehicles', id: vehicleId }, 'Depots'],
    }),
    getDepots: build.query<ListDepotTransformed[], string>({
      query: (solutionId) => ({ url: `v2/Solutions/${solutionId}/Depots` }),
      providesTags: (depots) => {
        const tags: any = ['Depots' as const];

        if (depots) {
          for (const depot of depots) {
            tags.push({ type: 'Depots' as const, id: depot.DepotId });
          }
        }

        return tags;
      },
      transformResponse: (response: ListDepotEnvelope) =>
        response.Content.Items.map((depot) => transformListDepot(depot)),
    }),
    getDepot: build.query<DepotTransformed, { solutionId: string; depotId: string }>({
      query: ({ solutionId, depotId }) => ({
        url: `v2/Solutions/${solutionId}/Depots/${depotId}`,
      }),
      providesTags: (_, __, { depotId }) => [{ type: 'Depots', id: depotId }],
      transformResponse: (response: GetDepotEnvelope) => transformDepot(response.Content),
    }),
    addDepot: build.mutation<CreateDepotResponseV2VM, CreateDepotArgs>({
      query: ({ solutionId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Depots`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { solutionId, MappedVehicles }) => {
        const tags: any[] = [{ type: 'Solutions' as const, id: solutionId }, 'Depots', 'HomeBases'];

        for (const vehicleId of MappedVehicles) {
          tags.push({ type: 'Vehicles' as const, id: vehicleId });
        }

        return tags;
      },
      transformResponse: (response: CreateDepotEnvelope) => response.Content,
    }),
    updateDepot: build.mutation<ApiEnvelopeNoContent, UpdateDepotArgs>({
      query: ({ solutionId, depotId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Depots/${depotId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { solutionId }) => [
        { type: 'Solutions', id: solutionId },
        'Depots',
        'Vehicles',
        'HomeBases',
      ],
    }),
    deleteDepots: build.mutation<ApiEnvelopeNoContent, DeleteDepotsArgs>({
      query: ({ solutionId, ids }) => {
        const queryIds = ids.map((id) => `Ids=${id}`).join('&');
        return {
          url: `v2/Solutions/${solutionId}/Depots?${queryIds}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => ['Depots', 'Vehicles', 'Solutions', 'HomeBases'],
    }),
    validateDepotSetup: build.mutation<ValidateDepotSetupResponseV2VM, CreateDepotArgs>({
      query: ({ solutionId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Depots/Validate`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ValidateDepotSetupEnvelope) => response.Content,
    }),
    getHomeBases: build.query<HomeBase[], string>({
      query: (solutionId) => ({ url: `v2/Solutions/${solutionId}/Depots/HomeBases` }),
      transformResponse: (response: GetHomeBasesEnvelope) => response.Content,
      providesTags: () => ['HomeBases'],
    }),
    addMapLocation: build.mutation<MapLocationEnvelope, RecursivePartial<MapLocationArgs>>({
      query: ({ solutionId, mapLocation }) => {
        const { ...body } = mapLocation;
        return {
          url: `v2/Solutions/${solutionId}/Map/Locations`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (_, __, { solutionId }) => [{ type: 'Solutions', id: solutionId }, 'Vehicles'],
    }),
    updateMapLocation: build.mutation<MapLocationEnvelope, RecursivePartial<MapLocationArgs>>({
      query: ({ solutionId, mapLocation }) => {
        const { ...body } = mapLocation;
        return {
          url: `v2/Solutions/${solutionId}/Map/Locations/${mapLocation?.Id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_, __, { solutionId }) => [{ type: 'Solutions', id: solutionId }, 'Vehicles'],
    }),
    deleteMapLocation: build.mutation<MapLocationEnvelope, { solutionId: number | string; mapLocationId: number }>({
      query: ({ solutionId, mapLocationId }) => ({
        url: `v2/Solutions/${solutionId}/Map/Locations/${mapLocationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { solutionId }) => [{ type: 'Solutions', id: solutionId }, 'Vehicles'],
    }),
    getUser: build.query<UserVM, void>({
      query: () => ({
        url: 'v1/Users/self',
      }),
      providesTags: () => ['User'],
      transformResponse: (response: GetUserEnvelope) => response.Content,
    }),
    updateOnboarding: build.mutation<null, void>({
      query: () => ({
        url: 'v1/Users/Onboarding',
        method: 'PUT',
      }),
      invalidatesTags: () => ['User'],
    }),
    updateTos: build.mutation<null, void>({
      query: () => ({
        url: 'v1/Users/tos',
        method: 'PUT',
      }),
      invalidatesTags: () => ['User'],
    }),
    initStops: build.mutation<InitStopsResponse, InitStopsArgs>({
      query: ({ solutionId, ...body }) => ({
        url: `v1/Solutions/${solutionId}/Stops/Init`,
        method: 'POST',
        body,
      }),
      extraOptions: { maxRetries: 2 },
      transformResponse: (response: InitStopsEnvelope) => response.Content,
    }),
    getStopsStatus: build.query<StopsStatus, GetStopsStatusArgs>({
      query: ({ solutionId, queryId }) => ({
        url: `v1/Solutions/${solutionId}/Stops/Status/${queryId}`,
      }),
      extraOptions: { maxRetries: 2 },
      transformResponse: (response: GetStopsStatusEnvelope) => response.Content,
    }),
    getStops: build.query<Stops, GetStopsArgs>({
      query: ({ solutionId, queryId }) => ({
        url: `v1/Solutions/${solutionId}/Stops/Results/${queryId}`,
      }),
      transformResponse: (response: GetStopsEnvelope) => response.Content,
    }),
    initTrips: build.mutation<InitTripsResponse, InitTripsArgs>({
      query: ({ solutionId, ...body }) => ({
        url: `v1/Solutions/${solutionId}/Trips/Init`,
        method: 'POST',
        body,
      }),
      extraOptions: { maxRetries: 2 },
      transformResponse: (response: InitTripsEnvelope) => response.Content,
    }),
    getTripsStatus: build.query<TripsStatus, GetTripsStatusArgs>({
      query: ({ solutionId, queryId }) => ({
        url: `v1/Solutions/${solutionId}/Trips/Status/${queryId}`,
      }),
      extraOptions: { maxRetries: 2 },
      transformResponse: (response: GetTripsStatusEnvelope) => response.Content,
    }),
    getTrips: build.query<Trips, GetTripsArgs>({
      query: ({ solutionId, queryId }) => ({
        url: `v1/Solutions/${solutionId}/Trips/Results/${queryId}`,
      }),
      transformResponse: (response: GetTripsEnvelope) => response.Content,
    }),
    getStopsMetadata: build.mutation<StopsMetadataWithTimeWindowResponseVM, GetStopsMetadataArgs>({
      query: ({ solutionId, VehicleIds }) => ({
        url: `v1/Solutions/${solutionId}/Stops/Metadata`,
        method: 'POST',
        body: { VehicleIds },
      }),
      transformResponse: (response: GetStopsMetadataEnvelope) => response.Content,
    }),
    getFactBasicData: build.query<FactBasicDataResponse, void>({
      query: () => ({ url: 'v1/FactBasicData' }),
      transformResponse: (response: GetFactBasicDataEnvelope) => response.Content,
    }),
    getPtoDevices: build.query<PtoDevices, void>({
      query: () => ({
        url: `v1/Pto`,
      }),
      transformResponse: (response: GetPtoEnvelope) => response.Content,
    }),
    deleteVehicles: build.mutation<ApiEnvelopeNoContent, DeleteVehiclesArgs>({
      query: ({ solutionId, ids }) => {
        const queryIds = ids.map((id) => `ids=${id}`).join('&');
        return {
          url: `v2/Solutions/${solutionId}/Vehicles?${queryIds}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_, __, { noInvalidates }) => (noInvalidates ? [] : ['Depots', 'Solutions', 'Vehicles']),
    }),
    updateVehicleTcoFigures: build.mutation<VehicleTcoSettingResponseVM, VehicleTcoArgs>({
      query: ({ solutionId, vehicleId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/tco/${vehicleId}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: UpdateTcoApiEnvelope) => response.Content,
      invalidatesTags: (_, __, { vehicleId }) => [{ type: 'Vehicles', id: vehicleId }],
    }),
    calculateVehicleTcoFigures: build.query<CalculateTcoEnvelope, VehicleTcoArgs>({
      query: ({ solutionId, vehicleId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/tco/${vehicleId}/calculate`,
        method: 'POST',
        body,
      }),
    }),
    getTcoVehicles: build.query<TcoVehicleVM[], GetTcoVehicleArgs>({
      query: ({ solutionId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/tco`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ListTcoVehicleEnvelope) => response.Content.Items.map((item) => item),
    }),
    getEnvironmentalImpact: build.query<EnvironmentalImpactVM, { solutionId: string; vehicleId: number }>({
      query: ({ solutionId, vehicleId }) => ({
        url: `v2/Solutions/${solutionId}/Vehicles/${vehicleId}/EnvironmentalImpact`,
      }),
      transformResponse: (response: ApiEnvelope<EnvironmentalImpactVM>) => response.Content,
      keepUnusedDataFor: 60 * 60 * 24 * 7,
      providesTags: (_, __, { vehicleId }) => [{ type: 'EnvironmentalImpact', id: vehicleId }],
    }),
    getCustomers: build.query<string[], GetCustomersArgs>({
      query: ({ searchTerm }) => ({
        url: `v1/Customers/Names?searchTerm=${encodeURIComponent(searchTerm)}`,
      }),
      transformResponse: (response: GetCustomersEnvelope) => response.Content.CustomerNames,
    }),
    getChassisByCustomerName: build.query<number[], GetChassisByCustomerNameArgs>({
      query: ({ customerName }) => ({
        url: `v1/Customers/ChassisNumbers?customerName=${encodeURIComponent(customerName)}`,
      }),
      transformResponse: (response: GetCustomersChassisEnvelope) => response.Content.ChassisNumbers,
    }),
    getChargingPoints: build.mutation<GeospatialChargingPoint[], void>({
      query: () => ({
        url: `v1/ChargingPoints`,
        method: 'POST',
        body: {},
      }),
      transformResponse: (response: ApiEnvelope<ChargingPointResponse>) => response.Content.ChargingPoints,
    }),
    routeAndCalculate: build.mutation<RoutePointsResponse | EmptyObject, RouteAndCalculateArgs>({
      query: ({ solutionId, vehicleId, ...body }) => ({
        url: `v1/Solutions/${solutionId}/Vehicles/${vehicleId}/Routes/RouteAndCalculate`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: RoutePointsEnvelope) => response.Content,
    }),
    getChassisRoute: build.query<RoutePointsResponse | EmptyObject, GetChassisRouteArgs>({
      query: ({ solutionId, vehicleId, longestDrivenDayEnabled }) => ({
        url: `v1/Solutions/${solutionId}/Vehicles/${vehicleId}/Routes/ChassisRoute?useLongestDrivenDay=${longestDrivenDayEnabled ? 'true' : 'false'}`,
      }),
      transformResponse: (response: RoutePointsEnvelope) => response.Content,
      keepUnusedDataFor: 0,
    }),
    getPresentationData: build.mutation<GetSolutionPresentationEnvelope, GetPresentationExportArgs>({
      query: ({ solutionId, ...body }) => ({
        url: `v2/Solutions/${solutionId}/Export`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useAddSolutionMutation,
  useAddChassisToSolutionMutation,
  useAddDepotMutation,
  useAddMapLocationMutation,
  useCreateManualVehicleMutation,
  useDeleteChargingEventsMutation,
  useDeleteDepotsMutation,
  useDeleteSolutionMutation,
  useDeleteMapLocationMutation,
  useDeleteVehiclesMutation,
  useGetFactBasicDataQuery,
  useGetDepotQuery,
  useGetDepotsQuery,
  useGetEnvironmentalImpactQuery,
  useGetHomeBasesQuery,
  useGetSolutionsQuery,
  useGetSolutionQuery,
  useGetStopsMetadataMutation,
  useGetStopsStatusQuery,
  useGetStopsQuery,
  useGetTripsStatusQuery,
  useGetTripsQuery,
  useGetPtoDevicesQuery,
  useGetUserQuery,
  useGetVehicleQuery,
  useGetVehiclesQuery,
  useInitStopsMutation,
  useInitTripsMutation,
  useUpdateDepotMutation,
  useUpdateSolutionMutation,
  useUpdateMapLocationMutation,
  useUpdateOnboardingMutation,
  useUpdateTosMutation,
  useLazyGetCustomersQuery,
  useLazyGetChassisByCustomerNameQuery,
  useGetTcoVehiclesQuery,
  useUpdateMultipleVehiclesMutation,
  useUpdateVehicleMutation,
  useUpdateVehicleChargingEventsMutation,
  useValidateDepotSetupMutation,
  useValidateChassisMutation,
  useValidateVehicleChargingEventsMutation,
  useUpdateVehicleTcoFiguresMutation,
  useCalculateVehicleTcoFiguresQuery,
  useGetChargingPointsMutation,
  useGetPresentationDataMutation,
  useLazyGetChassisRouteQuery,
  useRouteAndCalculateMutation,
} = solutionApi;
