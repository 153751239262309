import GlobalLoading from 'features/globalLoading/GlobalLoading';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { APP_TITLE, APP_USP } from 'app/config';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { defineCustomElements } from '@scania/tegel-react';
import { DrawerProvider } from 'app/context/DrawerContext';
import { ElementControllerProvider } from 'app/context/ElementControllerContext';
import { enableMocking } from './mocks/setup';
import { ExpandVehicleProvider } from 'app/context/ExpandVehicleAndTabContext';
import { FinancialEvaluationProvider } from 'app/context/FinancialEvaluationContext';
import { Helmet } from 'react-helmet';
import { Loading, ToastProvider, UpdateApp } from '@optimization/ssi-common';
import { msalConfig } from 'app/config/auth';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import { StickyHandlerProvider } from 'app/context/StickyHandlerContext';
import { store } from 'app/store';
import { TourProvider } from 'app/context/TourContext';

const Datadog = React.lazy(() => import('features/datadog'));
const DepotDetail = React.lazy(() => import('features/infrastructure/DepotDetail'));
const DepotManager = React.lazy(() => import('features/infrastructure/DepotManager'));
const EditDepot = React.lazy(() => import('features/infrastructure/EditDepot'));
const Header = React.lazy(() => import('features/navigation/Header'));
const Layout = React.lazy(() => import('common/components/Layout'));
const LightMode = React.lazy(() => import('common/components/LightMode'));
const LoadUser = React.lazy(() => import('features/auth/LoadUser'));
const PageNotFound = React.lazy(() => import('common/components/PageNotFound'));
const ProductManualOverview = React.lazy(() => import('features/productManual/ProductManualOverview'));
const ProductManualSubCategory = React.lazy(() => import('features/productManual/ProductManualSubCategory'));
const RouteError = React.lazy(() => import('common/components/RouteError'));
const SideNavbarDefault = React.lazy(() => import('features/navigation/SideNavbar/Default'));
const SideNavbarMinimal = React.lazy(() => import('features/navigation/SideNavbar/Minimal'));
const SideNavbarSolutionProductManual = React.lazy(() => import('features/navigation/SideNavbar/ProductManual'));
const SolutionDetail = React.lazy(() => import('features/solution/SolutionDetail'));
const SolutionHome = React.lazy(() => import('features/solution/SolutionHome'));
const SolutionManager = React.lazy(() => import('features/solution/SolutionManager'));
const SolutionPresentationConfigurator = React.lazy(() => import('features/solution/SolutionPresentationConfigurator'));

defineCustomElements();

export const msalInstance = new PublicClientApplication(msalConfig);

export const routes = {
  errorElement: (
    <Suspense fallback={<Loading isLoading />}>
      <RouteError />
    </Suspense>
  ),
  element: (
    <ElementControllerProvider>
      <Helmet>
        <title>
          {APP_TITLE} - {APP_USP}
        </title>
      </Helmet>
      <GlobalLoading />
      <Suspense fallback={<Loading isLoading />}>
        <LoadUser>
          <TourProvider>
            <Datadog>
              <UpdateApp appTitle={APP_TITLE}>
                <ExpandVehicleProvider>
                  <StickyHandlerProvider>
                    <Header />
                    <DrawerProvider>
                      <FinancialEvaluationProvider>
                        <Outlet />
                      </FinancialEvaluationProvider>
                    </DrawerProvider>
                  </StickyHandlerProvider>
                </ExpandVehicleProvider>
              </UpdateApp>
            </Datadog>
          </TourProvider>
        </LoadUser>
      </Suspense>
    </ElementControllerProvider>
  ),
  children: [
    {
      element: (
        <Layout>
          <Outlet />
        </Layout>
      ),
      children: [
        {
          element: (
            <SideNavbarMinimal>
              <SolutionManager />
            </SideNavbarMinimal>
          ),
          path: '/',
        },
        {
          element: (
            <SideNavbarDefault>
              <Outlet />
            </SideNavbarDefault>
          ),
          children: [
            {
              element: <SolutionHome />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/home',
            },
            {
              element: <SolutionDetail />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/candidate',
            },
            {
              element: <DepotManager />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/infrastructure',
            },
            {
              element: <DepotDetail />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/infrastructure/:depotId',
            },
            {
              element: <DepotDetail />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/infrastructure/:depotId/year/:yearParam',
            },
            {
              element: <EditDepot />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/infrastructure/:depotId/edit',
            },
            {
              element: <SolutionPresentationConfigurator />,
              errorElement: <RouteError />,
              path: '/solution/:solutionId/presentation-configurator',
            },
          ],
        },
        {
          path: 'callback',
        },
      ],
    },
    {
      element: (
        <LightMode>
          <SideNavbarSolutionProductManual>
            <Outlet />
          </SideNavbarSolutionProductManual>
        </LightMode>
      ),
      children: [
        {
          element: <ProductManualOverview />,
          errorElement: <RouteError />,
          path: '/product-manual/:categorySlug',
        },
        {
          element: <ProductManualSubCategory />,
          errorElement: <RouteError />,
          path: '/product-manual/:categorySlug/:subCategorySlug',
        },
      ],
    },
    {
      path: '/*',
      element: <PageNotFound className="mt-spacing-88" />,
    },
  ],
};

const router = createBrowserRouter([routes]);

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <ToastProvider>
            <RouterProvider router={router} />
          </ToastProvider>
        </Provider>
      </MsalProvider>
    </React.StrictMode>,
  );
});
