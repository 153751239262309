import { authConfig } from 'app/config/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MicrosoftUser } from '@optimization/sa-common';
import { RootState } from '../store';

const baseQuery = fetchBaseQuery({
  baseUrl: '',
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).auth.accessToken;

    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

export const microsoftApi = createApi({
  reducerPath: 'microsoftApi',
  baseQuery,
  endpoints: (build) => ({
    getMicrosoftUser: build.query<MicrosoftUser, void>({
      query: () => ({ url: authConfig.graphMeEndpoint }),
    }),
  }),
});

export const { useGetMicrosoftUserQuery } = microsoftApi;
