import { useEffect, useState } from 'react';

const TIME_BEFORE_LOADING_TEXT = 1500;

interface Props {
  isLoading: boolean;
}

const useLoadingText = ({ isLoading }: Props): string => {
  const [showLoading, setShowLoading] = useState(false);
  const [loadingStartTime, setLoadingStartTime] = useState<number | null>(null);

  useEffect(() => {
    if (isLoading) {
      if (loadingStartTime === null) {
        setLoadingStartTime(Date.now());
      }

      const timeout = setTimeout(() => {
        setShowLoading(true);
      }, TIME_BEFORE_LOADING_TEXT);

      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        if (loadingStartTime !== null && Date.now() - loadingStartTime >= TIME_BEFORE_LOADING_TEXT) {
          setShowLoading(false); // Hide only if it was active for at least required time
        } else if (loadingStartTime === null) {
          setShowLoading(false); // Hide if loading was never active
        }

        setLoadingStartTime(null);
      }, TIME_BEFORE_LOADING_TEXT);

      return () => clearTimeout(timeout);
    }
  }, [isLoading, loadingStartTime]);

  return showLoading ? 'Loading' : '';
};

export default useLoadingText;
