import { ExecutedVehicleProductPto, FactBasicBus, FactBasicTruck } from '@optimization/sa-common';

export const calculatePtoEc = (ptos: ExecutedVehicleProductPto[]) =>
  ptos.reduce((acc, curr) => acc + curr.ElectricalConsumption, 0);

export const isError404 = (error: any) => error && 'status' in error && error.status === 404;

export const getErrorMessage = (error: unknown): string => {
  if (error && error instanceof Error) {
    if (error.stack) {
      return error.stack;
    } else if (error.message) {
      return error.message;
    }
  }

  return '';
};

export const promiseAllSettledWrapper = async <T>(promises: Promise<T>[]) => {
  const results = await Promise.allSettled(promises);

  const isFulfilled = <T>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> => p.status === 'fulfilled';
  const isRejected = <T>(p: PromiseSettledResult<T>): p is PromiseRejectedResult => p.status === 'rejected';

  const fulfilled = results.filter(isFulfilled).map((f) => f.value);
  const rejected = results.filter(isRejected).map((f) => f.reason);
  return { fulfilled, rejected };
};

// Todo: Call API to log error
export const logError = (message: string) => {
  console.error('logError', message);
};

export const getEngineGearbox = (basicVehicle: FactBasicTruck | FactBasicBus, electricMotor: string) =>
  basicVehicle.EngineGearboxes.find((engineGearbox) => engineGearbox.Name === electricMotor) ||
  basicVehicle.EngineGearboxes[0];

export const getVehicleSoc = (basicVehicle: FactBasicTruck | FactBasicBus, socWindow: string) =>
  basicVehicle.SocSpecifications.find((soc) => soc.SocWindow.toString() === socWindow) ||
  basicVehicle.SocSpecifications[0];
