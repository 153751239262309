import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  idToken: string;
  accessToken: string;
  accessDenied: boolean;
}

const initialState: AuthState = {
  idToken: '',
  accessToken: '',
  accessDenied: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<{ idToken: string; accessToken: string }>) => {
      state.idToken = action.payload.idToken;
      state.accessToken = action.payload.accessToken;
    },
    setAccessDenied: (state, action: PayloadAction<{ accessDenied: boolean }>) => {
      state.accessDenied = action.payload.accessDenied;
    },
  },
});

export const { setTokens, setAccessDenied } = authSlice.actions;

export default authSlice.reducer;
